import Vue from 'vue'
import Router from 'vue-router'
import Login from'@/views/login/index'
Vue.use(Router)
const router = new Router({
    routes: [
      {
        path: '/',
        redirect: '/login'
      },
      {  
        path: '/login',
        name: 'Login',
        component: Login
      }
    ],
	mode: 'history'
})
export default router